<template>
  <div class="eatery-group">
    <Module-Header title="Eatery Group">
      <!-- <el-button class="export" @click="exportFile"><img src="@/assets/images/link.png" />Export</el-button> -->
      <el-button class="default" @click="importEatery">Import New</el-button>
      <!-- <el-button class="default">Import New</el-button> -->
      <el-button class="default" @click="$router.push({ path:'/eateryGroup/add'})">Create New</el-button>
    </Module-Header>

    <div class="search-box">
      <el-input placeholder="Keywords(e.g. eatery group ID or name)" 
        prefix-icon="el-icon-search" v-model="listQ.like_number_or_name"></el-input>
      
        <el-select v-model="listQ.name" clearable placeholder="All groups" style="margin-left: 20px;">
          <el-option
            v-for="(item,index) in groups" :key="index" :label="item.name" :value="item.id">
          </el-option>
        </el-select>

      <el-button class="search" icon="el-icon-search" @click="getData">
        Search
      </el-button>
    </div>

    <div class="table-box">
      <el-table :data="tableData" ref="table" style="width:100%;">
        <el-table-column v-for="(its, idx) in tableCate" :key="idx"
          :prop="its.prop" :label="its.label" :min-width="its.width" sortable> </el-table-column>
        <el-table-column width="130" align="center">
          <template slot-scope="scope">
            <div class="editor">
              <img src="@/assets/images/edit.png" @click="toEditPage(scope.row)" />
              <img src="@/assets/images/delete.png" @click="deleteEatery(scope.row.id)"/>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="total"
        :autoScroll='false'
        :page.sync="listQ._start"
        :limit.sync="listQ._limit"
        layout="prev, pager, next"
        @pagination="getData"
      />
    </div>

  </div>
</template>

<script>
import { getEateriesGroup, delEateriesGroup, bulkImportEateriesGroup } from '@/api/eateryGroup'
import exportToExcel from "@/utils/exportExcel";
import { read, utils } from 'xlsx'
import verification from './verification'

export default {
  data() {
    return {
      groups: [],

      tableCate: [
        {label: 'Eatery Group ID', prop: 'number', width: '170'},
        {label: 'Group', prop: 'name', width: '340'},
        {label: 'No. of Eateries', prop: 'NoofEateries', width: '220'},
      ],
      tableData: [],
      total: 5,
      listQ:{
        like_number_or_name: '',
        name: '',
        _start: 1,
        _limit: 999,
      },
    }
  },
  methods: {
    exportFile() {
      let data = this.tableData,
      tHeader = [],
      filterVal = [],
      title = 'Eatery Group'

      this.tableCate.filter(its => {
        tHeader.push(its.label)
        filterVal.push(its.prop)
      })
      
      exportToExcel(data, title, tHeader, filterVal, () => {
        
      })
    },
    deleteEatery(id) {
      this.$popUp.config({
        content: 'Please confirm whether to Delete',
        confirm: {
          e: () => {
            delEateriesGroup(id).then(() => {
              this.listQ._start = 1
              this.getData()
              this.$popUp.removePopup()
            })
          }
        }
      })
      
    },
    importEatery() {
      let file = document.createElement('input')
      file.type = 'file'
      file.onchange = this.readFile

      file.click()
    },
    readFile(e){//上传文件后读取excel文件内容
      let file = e.target.files[0];
      const types = ["xlsx", "xls"];
      const arr = file.name.split(".");
      //判断文件是否为excel文件
      if (!types.find(item => item === arr[arr.length - 1])) {
        alert('请选择正确的文件类型');
        return;
      }
      let reader = new FileReader();
      //启动函数
      reader.readAsBinaryString(file);
      reader.onload = e => {
        //workbook存放excel的所有基本信息
        let workbook = read(e.target.result, { type: "binary", cellDates: true });
        //定义sheetList中存放excel表格的sheet表，就是最下方的tab
        let sheetList = workbook.SheetNames;
        //读取文件内容，（第一个sheet里的内容）
        // range：设置从第几行开始读取内容
        let json = utils.sheet_to_json(workbook.Sheets[sheetList[0]],{range: 0});
        // this.handleXlsxData(json);
        // console.log(json)
        if(verification(json))
          this.handleXlsxData(json)
        else this.$message.error('檔案數據不完整，請重新檢查！')
      };
    },
    handleXlsxData(json) {
      let arr = []
      json.filter((its, ) => {
        let obj = {
          "name": {
            "zh_hant": its[`Eatery Group Name (TC)`],
            "en": its[`Eatery group name (EN)`]
          },
          logo: its[`Logo (image filename)`] ? this.imgUrl + its[`Logo (image filename)`] : '',
          "contact": {
            "name": its[`Contact Person`] || '',
            "phone_number": its[`Contact No.`] || '',
            "email_address": its[`Contact Email address`] || ''
          },
          "general": {
            "zh_hant": its[`General T&C (TC)`] || '',
            "en": its[`General T&C (EN)`] || ''
          },
          "user": {
            "username": its[`Username`],
            "password": its[`Password`]
          }
        }
        arr.push(obj)
      })

      console.log(arr)
      bulkImportEateriesGroup(arr).then(() => {
        this.$message.success('導入成功')
        this.getData()
      }).catch(err => {
        this.$message.error(err.data.error_message)
      })
    },
    getData() {
      let {listQ} = this, form = {
        like_number_or_name: listQ.like_number_or_name,
        name: listQ.name,
        _start: listQ._start - 1,
        _limit: listQ._limit,
      }

      getEateriesGroup(form).then(v => {
        v.data.filter(its => {
          its.en = its.name.en
          its.name = its.name.zhHant
          its.NoofEateries = its.eateries.length
        })
        this.tableData = v.data
        let group = v.data.sort((a, b) => {
          if (a.NoofEateries > b.NoofEateries) {
            if (a.en < a.en) return 1
            else return -1
          } return 1
        })
        this.groups = group
        this.total = Number(v.headers["x-total-count"]);
      })
    },
    toEditPage(row) {
      // console.log(row)
      this.$router.push({ path: `/eateryGroup/edit/${row.id}`})
    },
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.eatery-group {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  .search-box{
    display: flex;
    font-size: 0.875rem;
    width: 100%;
    margin-top: 3.125rem;

    .search {
      margin-left: 1.25rem;
    }
  }

}
</style>